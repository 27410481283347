import { Link } from "react-router-dom";
import Pages from "../utils/Pages";

function Navbar(): JSX.Element {
  return (
    <nav className="sticky-top">
      <div className="website-navbar">
        <Link to="/" className="mx-2" id="logo"><img src="logo.png" alt="Logo" width="50px" /></Link>
        <div><Link to="/" className="webiste-name">Offerte Di Fuoco</Link></div>
        { Pages.map((page) => page.showInNavbar && <div><Link to={page.path} className="website-navbar-text">{page.title}</Link></div>) }
        { Pages.map((page) => page.showInNavbar && <div><Link to={page.path} className={page.icon + " website-navbar-icon"}></Link></div>) }
        <div className="w-25" id="spacer"></div>
        <div><Link to="https://t.me/offertedifuoco" target="_blank" className="website-button bg-white text-black"><span className="website-navbar-text">Telegram</span> <i className="bi bi-telegram"></i></Link></div>
      </div>
    </nav>
  );
}

export default Navbar;