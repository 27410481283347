import Home from "../pages/Home";
import Offerte from "../pages/Offerte";
import GeneratoreLink from "../pages/GeneratoreLink";

export interface Page {
  title: string;
  description: string;
  icon: string;
  path: string;
  showInNavbar: boolean;
  element: () => JSX.Element;
}

const pages: Page[] = [
  Home,
  Offerte,
  GeneratoreLink,
];

export default pages;