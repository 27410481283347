import { BrowserRouter, Route, Routes } from "react-router-dom";
import pages from "./utils/Pages";
import Navbar from "./components/Navbar";
import { useState } from "react";
import { OfferContext } from "./contexts/OffersContext";
import Offer from "./interfaces/Offer";
import Footer from "./components/Footer";

function App() {
  const [offers, setOffers] = useState<Offer[]>([]);

  const addOffers = (offers: Offer[]) => {
    setOffers(_offers => [...new Set(offers)]);
  }

  const removeOffers = (offers: Offer[]) => {
    setOffers(_offers => _offers.filter(i => !offers.includes(i)));
  }

  const sortOffers = (sortingId: string) => {
    switch(sortingId) {
      case "1":
        setOffers(_offers => [..._offers.sort((a, b) => b.date - a.date)]);
        break;
      case "2":
        setOffers(_offers => [..._offers.sort((a, b) => a.date - b.date)]);
        break;
      case "3":
        setOffers([...offers.sort((a, b) => parseInt(a.price) - parseInt(b.price))]);
        break;
      case "4":
        setOffers([...offers.sort((a, b) => parseInt(b.price) - parseInt(a.price))]);
        break;
    }
  }

  return (
    <OfferContext.Provider value={{offers, addOffers, removeOffers, sortOffers}}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          { pages.map(page => <Route path={page.path} element={<page.element />} />) }
        </Routes>
        <Footer />
      </BrowserRouter>
    </OfferContext.Provider>

  );
}

export default App;
