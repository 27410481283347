import { Link } from "react-router-dom";

export interface OfferCardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  price: string;
  coupon: string;
  type?: string;
  link: string;
  image: string;
  date: number;
}

function OfferCard(props: OfferCardProps): JSX.Element {
  const title = props.title.replace("🔥 ", "").substring(0, 70) + "...";

  return (
    <Link to={props.link} target="_blank" className={props.className + " card text-decoration-none"}>
      <img src={props.image} alt="" className="card-img-top" />
      <div className="card-body border-top">
        <h6 className="card-title fw-bold website-link-hover text-black">{title}</h6>
      </div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item"><i className="bi bi-cash-stack me-1"></i> A soli: {props.price}€</li>
        { props.coupon && <li className="list-group-item"><i className="bi bi-ticket-perforated-fill me-1"></i> Coupon: <span className="font-monospace">{props.coupon}</span></li> }
      </ul>
      <div className="card-footer">
        <Link to={props.link} target="_blank" className="btn btn-primary w-100 border-0 fw-bold" style={{backgroundColor: "#ea4935"}}>COMPRA ORA</Link>
      </div>
    </Link>
  );
}

export default OfferCard;