import { useContext, useEffect } from "react";
import { Page } from "../utils/Pages";
import { getLatestOffers } from "../utils/Utils";
import OfferCard from "../components/OfferCard";
import { Link } from "react-router-dom";
import { OfferContext } from "../contexts/OffersContext";
import ProvaAmazonPrimeImage from "../assets/images/prova_amazon_prime.png"
import ProvaAudibleImage from "../assets/images/prova_audible.png"
import ProvaStudenteImage from "../assets/images/prova_studente.png"

function Element(): JSX.Element {
  const offersContext = useContext(OfferContext);

  useEffect(() => {
    offersContext.sortOffers("1");

    if (offersContext.offers.length !== 0) return;

    getLatestOffers()
    .then(offers => offers && offersContext.addOffers(offers));
  }, []);

  return (
    <div className="base-page">
      <div className="my-5 alert alert-primary text-center">
        Entra nel canale Telegram per essere notificato su tutte le offerte giornaliere! <Link to="https://t.me/offertedifuoco" target="_blank" className="alert-link">@offertedifuoco</Link>
      </div>

      <hr />
      
      <div className="grid-container-4 my-5">
        <Link to="http://www.amazon.it/provaprime?tag=proprime00-21" target="_blank">
          <img src={ProvaAmazonPrimeImage} alt="Prova gratis Amazon Prime" className="w-100 rounded-4 shadow" />
        </Link>
        <Link to="https://www.amazon.it/hz/audible/mlp?actionCode=AZIOther35606092201BR&tag=proprime00-21" target="_blank">
          <img src={ProvaAudibleImage} alt="Prova gratis Audible" className="w-100 rounded-4 shadow" />
        </Link>
        <Link to="http://www.amazon.it/joinstudent?tag=proprime00-21" target="_blank">
          <img src={ProvaStudenteImage} alt="Prova gratis Prime Students" className="w-100 rounded-4 shadow" />
        </Link>
        <Link to="http://www.amazon.it/provaprime?tag=proprime00-21" target="_blank">
          <img src={ProvaAmazonPrimeImage} alt="Prova gratis Amazon Prime" className="w-100 rounded-4 shadow" />
        </Link>
      </div>

      <hr />

      <div  className="my-5">
        <h3 className="fw-bold text-center">ULTIME OFFERTE</h3>
        <div className="grid-container-4 my-5">
          { offersContext.offers?.slice(0, 4).map(offer => <OfferCard {...offer} />) }
        </div>
        <Link to="/offerte" className="btn btn-primary shadow p-2 w-100 border-0" style={{backgroundColor: "#ea4935"}}>Vedi altre offerte</Link>
      </div>
    </div>
  );
}

const Home: Page = {
  title: "Home",
  description: "",
  icon: "bi bi-house-door-fill",
  path: "/",
  showInNavbar: true,
  element: Element,
};

export default Home;