import { useState } from "react";
import { Page } from "../utils/Pages";
import { Link } from "react-router-dom";

function Element(): JSX.Element {
  const [productLink, setProductLink] = useState("");
  const [referralLink, setReferralLink] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState("");

  const createReferralLink = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setReferralLink(undefined);
    setErrorMessage("");

    try {
      const url = new URL(productLink);
      url.searchParams.set("tag", "proprime00-21");
      setReferralLink(url.toString());
    } catch (ignored: unknown) {
      setErrorMessage("Errore! Link non valido!");
    }
  }

  return (
    <div className="base-page">
      <h1 className="text-center fw-bold mb-4">{Offerte.title.toUpperCase()}</h1>

      <form onSubmit={createReferralLink}>
        <input type="text" required className="form-control my-2" placeholder="Link prodotto" value={productLink} onChange={e => setProductLink(e.target.value)} />
        <input type="submit" className="btn w-100 text-white my-2" value="Genera" style={{backgroundColor: "#ea4935"}} />
      </form>

      {
        referralLink && 
        <div className="alert alert-success my-2">
          <Link to={referralLink} className="alert-link">{referralLink}</Link>
        </div>
      }

      {
        errorMessage &&
        <div className="alert alert-danger my-2">
          {errorMessage}
        </div>
      }
    </div>
  );
}

const Offerte: Page = {
  title: "Generatore Link",
  description: "",
  icon: "bi bi-link-45deg",
  path: "/generatore-link",
  showInNavbar: true,
  element: Element,
};

export default Offerte;