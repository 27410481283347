import { useContext, useEffect } from "react";
import { Page } from "../utils/Pages";
import OfferCard from "../components/OfferCard";
import { getLatestOffers } from "../utils/Utils";
import { Link } from "react-router-dom";
import { OfferContext } from "../contexts/OffersContext";

function Element(): JSX.Element {
  const offersContext = useContext(OfferContext);

  useEffect(() => {
    offersContext.sortOffers("1");

    if (offersContext.offers.length !== 0) return;

    getLatestOffers()
    .then(offers => offers && offersContext.addOffers(offers));
  }, []);

  return (
    <div className="base-page">
      <h1 className="text-center fw-bold mb-4">{Offerte.title.toUpperCase()}</h1>

      <div className="container">
        <div className="row justify-content-end">
          <select className="form-select mt-3 w-50 col-4" onChange={e => offersContext.sortOffers(e.target.value)}>
            <option value="1">Più recente</option>
            <option value="2">Meno recente</option>
            <option value="3">Prezzo (Crescente)</option>
            <option value="4">Prezzo (Decrescente)</option>
          </select>
        </div>
      </div>
      
      <div className="text-center my-4">
        { offersContext.offers.length === 0 && <div className="spinner-grow text-danger" style={{width: "50px", height: "50px"}}><span className="visually-hidden">Loading...</span></div> }
      </div>

      <div className="grid-container-4 mt-3">
        { offersContext.offers?.map(offer => <OfferCard {...offer} className="col" />) }
      </div>

      <div className="my-5 alert alert-primary text-center">
        Per vedere tutte le offerte unisicti al canale <Link to="https://t.me/offertedifuoco" target="_blank" className="alert-link">Telegram</Link>!
      </div>
    </div>
  );
}

const Offerte: Page = {
  title: "Offerte",
  description: "",
  icon: "bi bi-tags-fill",
  path: "/offerte",
  showInNavbar: true,
  element: Element,
};

export default Offerte;