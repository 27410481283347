import { createContext } from "react";
import Offer from "../interfaces/Offer";

export interface OfferContextProps {
  offers: Offer[];
  addOffers: (offers: Offer[]) => void;
  removeOffers: (offers: Offer[]) => void;
  sortOffers: (sortingId: string) => void;
}

export const OfferContext = createContext<OfferContextProps>({
  offers: [],
  addOffers: () => {},
  removeOffers: () => {},
  sortOffers: () => {},
});